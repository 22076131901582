<template>
  <div class="from_outer">
    <div>
      <el-form
        ref="formName"
        label-width="180px"
        :rules="rules"
        :model="formLabelAlign"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="姓名："
              prop="truename"
              :rules="[{ required: true, message: '请输入姓名' }]"
            >
              <el-input
                placeholder="请输入姓名"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.truename"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="责任部门："
              prop="department_id"
              :rules="[{ required: true, message: '请选择责任部门' }]"
            >
              <Bepartment
                ref="Bepartment"
                class="form-inp"
                v-model="formLabelAlign.department_id"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="手机号：" prop="phone">
              <el-input
                placeholder="请输入手机号"
                size="mini"
                class="form-inp"
                v-model.number="formLabelAlign.phone"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="学校住址："
              prop="address"
              :rules="[{ required: true, message: '请输入学校住址' }]"
            >
              <el-input
                placeholder="请输入学校住址"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.address"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="身份证号："
              prop="idcard"
              :rules="[{ required: true, message: '请输入身份证号' }]"
            >
              <el-input
                placeholder="请输入身份证号"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.idcard"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="家庭住址："
              prop="home_address"
              :rules="[{ required: true, message: '请输入家庭住址' }]"
            >
              <el-input
                placeholder="请输入家庭住址"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.home_address"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="紧急联系人1："
              prop="contact_1"
              :rules="[{ required: true, message: '请输入紧急联系人1' }]"
            >
              <el-input
                placeholder="请输入紧急联系人1"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.contact_1"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="紧急联系人1手机号：" prop="contact_1_phone">
              <el-input
                placeholder="请输入紧急联系人1手机号"
                size="mini"
                class="form-inp"
                v-model.number="formLabelAlign.contact_1_phone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="紧急联系人2：">
              <el-input
                placeholder="请输入紧急联系人2"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.contact_2"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="紧急联系人2手机号：" prop="contact_2_phone">
              <el-input
                placeholder="请输入紧急联系人2手机号"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.contact_2_phone"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="评估标准：">
              <el-select
                v-model="formLabelAlign.level_type"
                placeholder="请选择评估标准"
                class="form-inp"
              >
                <el-option
                  v-for="item in estimate_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="评估等级：">
              <el-select
                v-model="formLabelAlign.level_color"
                placeholder="请选择评估等级"
                class="form-inp"
              >
                <el-option
                  v-for="item in color_level"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="id">
            <el-form-item
              label="管控状态："
              prop="type"
              :rules="[{ required: true, message: '请选择管控状态' }]"
            >
              <el-select
                class="form-inp"
                v-model="formLabelAlign.type"
                placeholder="管控状态："
              >
                <el-option
                  v-for="item in status_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="类型：" prop="cate_id">
              <el-cascader
                class="form-inp"
                v-model="formLabelAlign.cate_id"
                :options="bepartment_Data"
                :props="{
                  checkStrictly: true,
                  label: 'name',
                  children: 'items',
                  value: 'id',
                }"
                @change="dataChange"
              ></el-cascader>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="考核分数" prop="score">
              <el-input
                placeholder="考核分数"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.score"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <div class="execute">
              <el-form-item label="帮扶周期：">
                <el-select
                  v-model="formLabelAlign.check_times"
                  placeholder="请选择帮扶周期"
                  class="form-inp"
                >
                  <el-option
                    v-for="item in check_times"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                label-width="0px"
                v-if="formLabelAlign.check_times == 5"
                prop="times_days"
              >
                <el-input
                  style="width: 200px"
                  placeholder="请输入天数"
                  size="mini"
                  class="form-inp"
                  v-model.number="formLabelAlign.times_days"
                >
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </div>
          </el-col>

          <!-- <el-col :span="24">
            <el-form-item label="风险源标签：">
              <div class="tag-sty">
                <div>
                  <el-tag
                    v-for="(tag, index) in formLabelAlign.tags_list"
                    :key="index"
                    style="margin: 0 5px 10px 0"
                    closable
                    @close="on_tabclose(index)"
                  >
                    {{ tag.name }}
                  </el-tag>
                </div>
              </div>
              <div>
                <el-button @click="label_show = true" type="primary"
                  >添加风险源标签</el-button
                >
              </div>
            </el-form-item>
          </el-col> -->
          <el-col :span="24" style="height: 100%">
            <el-form-item label="说明：" prop="desc">
              <el-input
                type="textarea"
                placeholder="请输入说明"
                size="mini"
                class="form-textarea"
                v-model="formLabelAlign.desc"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div style="text-align: center">
      <el-button @click="on_preserve('formName')" type="primary">{{
        type_name
      }}</el-button>
      <el-button @click="route_go">返回</el-button>
    </div>
    <!-- 风险源标签 -->
    <el-dialog
      show-close
      width="80%"
      title="风险源标签"
      :visible.sync="label_show"
      append-to-body
    >
      <div class="from_submit">
        <el-form label-width="120px" :model="label_from" class="from">
          <el-form-item label="用户ID：">
            <el-input
              placeholder="请输入用户ID"
              size="mini"
              class="pop-inp"
              v-model="label_from.id"
            ></el-input>
          </el-form-item>
          <el-form-item label="风险源名称：">
            <el-input
              placeholder="请输入风险源名称"
              size="mini"
              class="pop-inp"
              v-model="label_from.name"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="search_button">
          <div>
            <el-button
              @click="way_labelList(true)"
              type="primary"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-button @click="on_reset(label_from)" icon="el-icon-refresh-left"
              >重置</el-button
            >
          </div>
        </div>
      </div>
      <el-table
        :modal-append-to-body="false"
        ref="labeltable"
        highlight-current-row
        height="300px"
        :data="label_List"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="name" label="标签名"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              class="list_sty"
              type="primary"
              @click="on_addlabel(scope.row)"
              >添加</el-link
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="on_change"
          :current-page="label_from.page"
          :page-size="label_from.page_size"
          :total="label_total"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  personnelsaveInfo,
  tagsindex,
  personnelsave,
} from "../../assets/request/api";
import Bepartment from "../../components/Bepartment";
export default {
  name: "focus_groupsredact",
  components: {
    Bepartment,
  },
  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  data() {
    let phoneRuel = (rule, value, callback) => {
      if (!this.$tools.verify_phone(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };

    let phoneRuel_2 = (rule, value, callback) => {
      if (value) {
        if (!this.$tools.verify_phone(value)) {
          callback(new Error("请输入正确的手机号码"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let skyRuel = (rule, value, callback) => {
      if (!this.$tools.verify_sky(value)) {
        callback(new Error("请输入正确天数"));
      } else {
        callback();
      }
    };
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      options: [],
      check_times: [],
      bepartment_Data: [],
      estimate_type: [],
      color_level: [],
      status_list: "",
      formLabelAlign: {
        department_id: "",
        desc: "",
        truename: "",
        phone: "",
        address: "",
        home_address: "",
        idcard: "",
        contact_1: "",
        contact_2: "",
        contact_1_phone: "",
        contact_2_phone: "",
        check_times: "",
        cate_id: "",
        level_type: "",
        check_id: "",
        times_days: "",
        type: "",

        tags_list: [],
        level_color: "",
        score: 0,
      },
      rules: {
        times_days: [
          { validator: skyRuel, trigger: "blur" },
          { required: true, message: "请输入天数" },
        ],
      },
      //标签所需参数和列表
      label_List: [],
      label_from: {
        id: "",
        name: "",
        is_enable: "1",
        page_size: 10,
        page: 1,
      },
      label_total: 0,
      label_show: false,
      //当前选择的标签
      label_select: [],
      timeData: [],
      teacher_Data: [],
      TSmaster_id: "",

      list: [],

      rules: {
        contact_1_phone: [
          { validator: phoneRuel, trigger: "blur" },
          { required: true, message: "请输入紧急联系人1手机号" },
        ],
        contact_2_phone: [
          { validator: phoneRuel_2, trigger: "blur" },
          { required: false },
        ],
        phone: [
          { validator: phoneRuel, trigger: "blur" },
          { required: true, message: "请输入用户手机号" },
        ],
      },
    };
  },
  created() {
    this.way_labelList();
  },
  methods: {
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      personnelsaveInfo(froms).then((res) => {
        let {
          info,
          status_list,
          check_times,
          color_level,
          cate,
          estimate_type,
        } = res.data;
        this.color_level = color_level;
        this.bepartment_Data = cate;
        this.status_list = status_list;
        this.check_times = check_times;
        this.estimate_type = estimate_type;
        console.log(id);
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let {
            truename,
            desc,
            department_id,
            phone,
            address,
            home_address,
            idcard,
            contact_1,
            contact_2,
            contact_1_phone,
            contact_2_phone,
            type,
            check_times,
            check_id,
            level_type,
            level_color,
            score,
            tags,
            cate_id,
            tag_list,
            times_days,
          } = info;
          console.log(tags);

          formLabelAlign.id = id;
          formLabelAlign.tags_list = tag_list;
          this.$refs.Bepartment.bepartment_List = department_id;
          formLabelAlign.department_id = department_id;
          formLabelAlign.truename = truename;
          formLabelAlign.desc = desc;
          formLabelAlign.phone = phone;
          formLabelAlign.address = address;
          formLabelAlign.home_address = home_address;
          formLabelAlign.idcard = idcard;
          formLabelAlign.cate_id = cate_id;

          formLabelAlign.contact_1 = contact_1;
          formLabelAlign.contact_2 = contact_2;
          formLabelAlign.contact_1_phone = contact_1_phone;
          formLabelAlign.contact_2_phone = contact_2_phone;
          formLabelAlign.check_times = check_times;
          formLabelAlign.times_days = times_days;
          formLabelAlign.type = type;
          formLabelAlign.check_id = check_id;
          formLabelAlign.level_type = level_type;
          formLabelAlign.level_color = level_color;
          formLabelAlign.score = score;
        }
      });
    },
    on_timeData() {
      let { timeData, formLabelAlign } = this;
      if (timeData == null) {
        formLabelAlign.begin_time = "";
        formLabelAlign.end_time = "";
      } else {
        formLabelAlign.begin_time = timeData[0];
        formLabelAlign.end_time = timeData[1];
      }
    },
    on_change(val) {
      this.label_from.page = val;
      this.way_labelList();
    },
    dataChange(event) {
      let events = event[event.length - 1];
      this.formLabelAlign.cate_id = events;
    },
    //添加标签
    on_addlabel(row) {
      console.log(row);
      let { tags_list } = this.formLabelAlign;
      let is_join = tags_list.some((item) => {
        return item.id == row.id;
      });
      if (is_join) {
        this.$message({
          message: "当前风险源标签已添加，请勿重复添加",
          type: "warning",
        });
      } else {
        tags_list.push(row);
        this.$message({
          message: "添加风险源标签成功",
          type: "success",
        });
      }
    },
    //删除标签
    on_tabclose(index) {
      this.formLabelAlign.tags_list.splice(index, 1);
    },
    //风险源标签
    way_labelList(type) {
      if (type) {
        this.label_from.page = 1;
      }
      let { label_from } = this;
      tagsindex(label_from).then((res) => {
        this.label_total = res.data.total;
        this.label_List = res.data.data;
      });
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));
          let tags = "";
          // if (formLabelAlign.tags_list.length != 0) {
          //   tags = formLabelAlign.tags_list
          //     .map((obj) => {
          //       return obj.id;
          //     })
          //     .join(",");
          // }

          delete formLabelAlign.tags_list;
          formLabelAlign.tags = tags;

          personnelsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.$emit("update:refbool", false);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
  // computed: {
  //   department: function () {
  //     return this.formLabelAlign.department_id;
  //   },
  // },

  // watch: {
  //   //监听部门修改班级
  //   department(id) {
  //     this.formLabelAlign.master_id = "";
  //     usergetUserByDepart({ department_id: id }).then((res) => {
  //       let { data } = res;
  //       this.teacher_Data = data;
  //       console.log(this.teacher_Data);
  //       if (this.TSmaster_id) {
  //         this.formLabelAlign.master_id = this.TSmaster_id;
  //         this.TSmaster_id = "";
  //       }
  //     });
  //   },
  // },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
  height: 32px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
.form-textarea {
  width: 400px;
  margin-bottom: 10px;
}
.execute {
  display: flex;
}

.execute /deep/ .el-input__inner {
  height: 32px;
  margin-top: 1px;
}
.el-col {
  height: 50px;
}
</style>